.ProjectGrid {
   padding-top: 0;
   max-width: 100vw;
   width: 100%;
}

.ProjectGrid .grid-title {
   color: var(--white-color);
   margin-bottom: var(--spacing-3x);
   font-size: 20px;
   text-align: left;
}

.ProjectGrid .projects {
   flex-wrap: wrap;
   justify-content: space-between;
}

.ProjectGrid .projects .ProjectGridItem {
   flex-basis: 100%;
   flex-grow: 1;
   flex-shrink: 0;
}

@media only screen and (min-width: 1380px) {
   .ProjectGrid {
      padding-top: var(--spacing-20x);
      max-width: 724px;
      width: 100%;
   }

   .ProjectGrid .grid-title {
      margin-bottom: var(--spacing-6x);
      font-size: 24px;
   }
}