.MainLinks {
   display: none;
   position: fixed;
   left: 60px;
   bottom: 60px;
}

.MainLinks .join {
   background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
   color: var(--white-color);
   cursor: pointer;
   font-size: var(--font-size-small);
   font-weight: 600;
   outline: none;
   animation: gradient 15s ease infinite;
   margin-right: var(--spacing-2x);
   background-size: 400% 400%;
   text-decoration: none;
   transition: all 150ms linear;
}

.MainLinks .join:hover {
   transform: scale(1.04, 1.04);
}

.MainLinks .join,
.MainLinks .link {
   border-radius: var(--spacing-1x);
   margin-left: var(--spacing-2x);
   padding: var(--spacing-2x) var(--spacing-3x);
   text-decoration: none;
}

.MainLinks .link {
   color: var(--white-color);
   font-size: 15px;
   font-weight: bold;
   transition: all 150ms linear;
}

.MainLinks .link:hover {
   background-color: var(--off-black-color);
   text-decoration: none;
}

.MainLinks .join {
   margin-left: 0;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@media only screen and (min-width: 1380px) {
   .MainLinks {
      display: block;
   }   
}
