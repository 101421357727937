.LoginFormPopup-content {
    background-color: var(--black-color);
    border: 0px;
    max-width: 440px;
    min-height: 100vh;
    overflow: hidden;
    padding: 0;
    width: 100%;
}

.LoginFormPopup-content .close {
    background-color: var(--black-color);
    color: var(--white-color);
    position: absolute;
    cursor: pointer;
    right: var(--spacing-2x);
    top: var(--spacing-2x);
    padding: var(--spacing-2x);
    outline: none;
    z-index: 10;
}

.LoginFormPopup-content button {
    border: 0;
    border-radius: var(--spacing-2x);
    box-shadow: none;
    cursor: pointer;
    padding: var(--spacing-4x) var(--spacing-6x);
    outline: none;
    transition: all 150ms linear;
}

.LoginForm {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: var(--spacing-3x) var(--spacing-3x);
    max-width: min(440px, 100vw);
}

.SignUpForm .inputs {
    min-height: 194px;
    position: relative;
    width: 100%;
}

.LoginForm .container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
}

.LoginForm .title {
    color: var(--text-light-color);
    font-size: var(--font-size-large);
    margin-top: 0;
    margin-right: auto;
}

.LoginForm .Label.join {
    color: var(--grey4-color);
}

.LoginForm input {
    margin-bottom: var(--spacing-4x);
    outline: none;
    padding: var(--spacing-3x) var(--spacing-4x);
    width: 100%;
}

.LoginForm .divider {
    background-color: var(--grey1-color);
    height: 1px;
    margin-bottom: var(--spacing-8x);
    margin-top: var(--spacing-14x);
    position: relative;
    width: 100%;
}

.LoginForm .divider .Label {
    background-color: var(--black-color);
    color: var(--grey1-color);
    position: absolute;
    padding: var(--spacing-4x);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.LoginForm button {
    border: 0;
    border-radius: var(--spacing-2x);
    box-shadow: none;
    cursor: pointer;
    margin-top: var(--spacing-4x);
    padding: var(--spacing-4x) var(--spacing-6x);
    outline: none;
    transition: all 150ms linear;
    width: 100%;
}

.LoginForm button.login {
    background: var(--black-color);
    border: 2px dashed var(--green-color);
    border-radius: var(--spacing-1x);
    color: var(--green-color);
    font-size: var(--font-size-small);
    font-weight: bold;
}

.LoginForm button.login:disabled {
    background-color: var(--black-color);
    border-color: var(--border-dark-color);
    color: var(--border-dark-color);
}

.LoginForm button.login:hover {
    background: var(--black-color);
}

.LoginForm button.google {
    align-items: center;
    background: var(--black-color);
    border: 2px dashed var(--border-dark-color);
    color: var(--white-color);
    display: flex;
    font-size: var(--font-size-small);
    font-weight: bold;
    justify-content: center;
    margin-bottom: var(--spacing-8x);
    margin-top: var(--spacing-3x);
}

.LoginForm button.google:hover {
    border-color: var(--white-color);
}

.LoginForm button.google .Label {
    margin-left: var(--spacing-2x);
}

.LoginForm .join {
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (min-width: 600px) {
    
    .LoginFormPopup-content {
        border: 1px solid var(--grey1-color);
        background-color: var(--black-color);
        max-width: 440px;
        min-height: 558px;
        border: 2px solid var(--white-color);
        width: 100%;
    }

    .LoginForm {
        padding: var(--spacing-8x) var(--spacing-14x);
        max-height: 800px;
    }

    .LoginFormPopup-content .close {
        background-color: var(--black-color);
    }

    .LoginFormPopup-content .close:hover {
        background-color: var(--light-grey4-color);
    }
}
