.EditUserFormPopup-content {
    background-color: var(--black-color);
    max-height: 100vh;
    max-width: 100%;
    height: 100vh;
    padding: 0;
    overflow: hidden;
    border: 0px;
    width: 100%;
}

.EditUserFormPopup-content .close {
    background-color: var(--black-color);
    color: var(--white-color);
    position: absolute;
    cursor: pointer;
    right: var(--spacing-2x);
    top: var(--spacing-2x);
    padding: var(--spacing-2x);
    outline: none;
    z-index: 10;
}

.EditUserFormPopup-content button {
    border: 0;
    border-radius: var(--spacing-2x);
    box-shadow: none;
    cursor: pointer;
    padding: var(--spacing-4x) var(--spacing-6x);
    outline: none;
    transition: all 150ms linear;
}

.EditUserForm {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: var(--spacing-6x) var(--spacing-3x) var(--spacing-30x);
    max-width: min(600px, 100vw);
    max-height: 100vh;
    overflow: auto;
}

.EditUserForm .container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
}

.EditUserForm .title {
    font-size: var(--font-size-large);
    margin-bottom: var(--spacing-8x);
    margin-top: 0;
    margin-right: auto;
}

.EditUserForm .subtitle {
    color: var(--grey4-color);
    margin-bottom: var(--spacing-2x);
    margin-top: var(--spacing-6x);
    text-align: left;
    width: 100%;
}

.EditUserForm input,
.EditUserForm textarea {
    box-shadow: var(--box-shadow);
    margin-bottom: var(--spacing-4x);
    outline: none;
    padding: var(--spacing-1x) var(--spacing-2x);
    resize: none;
    width: 100%;
}

.EditUserForm textarea {
    height: 140px;
}

.EditUserForm .divider {
    background-color: var(--light-grey1-color);
    height: 1px;
    margin-bottom: var(--spacing-8x);
    margin-top: var(--spacing-14x);
    position: relative;
    width: 100%;
}

.EditUserForm .divider .Label {
    background-color: var(--black-color);
    position: absolute;
    padding: var(--spacing-4x);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.EditUserFormPopup-content button.customerPortal,
.EditUserFormPopup-content button.logout {
    margin-bottom: var(--spacing-1x);
    min-height: 80px;
    flex-grow: 1;
}

.EditUserFormPopup-content button.customerPortal,
.EditUserFormPopup-content button.logout,
.EditUserFormPopup-content button.save {
    background: var(--black-color);
    border: 2px dashed var(--border-dark-color);
    border-radius: var(--spacing-2x);
    box-shadow: none;
    cursor: pointer;
    margin-top: var(--spacing-2x);
    padding: var(--spacing-4x) var(--spacing-6x);
    outline: none;
    color: var(--white-color);
    font-size: var(--font-size-small);
    font-weight: bold;
    width: 100%;
}

.EditUserFormPopup-content button.save {
    border: 0px;
    color: var(--green-color);
    height: 100%;
    min-height: 80px;
    margin-top: 0px;
}

.EditUserFormPopup-content button.save:disabled {
    color: var(--border-dark-color);
    cursor: not-allowed;
}

.EditUserFormPopup-content button.customerPortal:hover,
.EditUserFormPopup-content button.logout:hover,
.EditUserFormPopup-content button.save:hover {
    background: var(--black-color);
}

.EditUserFormPopup-content button.customerPortal {
    margin-right: var(--spacing-2x);
}

.EditUserFormPopup-content button.logout {
    margin-left: var(--spacing-2x);
}

.EditUserFormPopup-content button.customerPortal:disabled,
.EditUserFormPopup-content button.logout:disabled {
    background-color: var(--light-grey1-color);
    color: var(--grey3-color);
}

.EditUserFormPopup-content button.save:disabled {
    background-color: var(--black-color);
}

.EditUserFormPopup-content .buttons {
    background-color: var(--black-color);
    border-top: 1px solid var(--border-dark-color);
    display: flex;
    position: fixed;
    justify-content: space-between;
    left: 0;
    bottom: 0;
    width: 100%;
}

.EditUserForm .account-container {
    width: 100%;
}

@media only screen and (min-width: 600px) {
    .EditUserFormPopup-content {
        border: 2px solid var(--white-color);
        background-color: var(--black-color);
        max-width: 600px;
        max-height: min(800px, 100vh);
        width: 100%;
    }

    .EditUserFormPopup-content .close {
        background-color: var(--black-color);
    }

    .EditUserFormPopup-content .close:hover {
        background-color: var(--black-color);
    }

    .EditUserForm {
        padding: var(--spacing-3x) var(--spacing-6x) var(--spacing-30x);
        max-height: min(800px, 100vh);
    }
}
