/** @format */

.ProjectGridItem {
	align-items: center;
	justify-content: flex-start;
	background-color: var(--black-color);
	border-top: 1px dashed var(--border-dark-color);
	cursor: pointer;
	position: relative;
	transition: all 150ms linear;
	padding-bottom: var(--spacing-2x);
	padding-top: var(--spacing-2x);
	z-index: 11;
	width: 100%;
}

.ProjectGridItem .index {
	color: var(--grey2-color);
	font-size: var(--font-size-small);
	font-weight: bold;
	width: 30px;
}

.ProjectGridItem .badge {
	background-color: var(--black-color);
	border-radius: var(--spacing-2x);
	box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.04);
	display: none;
	-webkit-box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.04);
	-moz-box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.04);
	font-size: var(--font-size-xsmall);
	font-weight: bold;
	pointer-events: none;
	text-align: left;
	padding: 2px var(--spacing-2x);
	position: absolute;
	text-transform: uppercase;
	top: -10px;
	left: -12px;
	z-index: 9;
}

.ProjectGridItem .badge-shadow {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 9px 10px 0;
	border-color: transparent rgba(0, 0, 0, 0.2) transparent transparent;
	position: absolute;
	text-transform: uppercase;
	top: 10px;
	left: -9px;
	z-index: 8;
}

.ProjectGridItem:hover {
	border-color: var(--white-color);
}

.ProjectGridItem .content {
	width: 100%;
}

.ProjectGridItem .top {
	width: 100%;
}

.ProjectGridItem .top .header {
	width: 100%;
}

.ProjectGridItem .top .title {
	color: var(--white-color);
	font-size: var(--font-size-small);
	font-weight: 600;
	transition: all 150ms linear;
	transform-origin: left center;
	max-width: 120px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: left;
}

.ProjectGridItem .desc {
	color: var(--grey4-color);
	font-size: 14px;
	text-align: left;
}

.ProjectGridItem .factoid {
	height: 44px;
	line-height: 44px;
	font-size: 13px;
	width: 100%;
}

.ProjectGridItem .bottom {
	width: 100%;
}

.ProjectGridItem button.edit {
	background-color: transparent;
	border: none;
	border-radius: 6px;
	color: var(--grey4-color);
	height: 44px;
	min-width: 32px;
	cursor: pointer;
	outline: none;
	padding-right: 0;
	margin-right: 0;
	transition: all 150ms linear;
}

.ProjectGridItem button.edit:hover {
	background-color: var(--off-black-color);
}

.ProjectGridItem .active {
	border-radius: 20%;
	background: var(--green-color);
	min-height: 8px;
	max-height: 8px;
	min-width: 8px;
	width: 8px;
	-webkit-box-shadow: 0px 0px 6px 1px rgba(0, 183, 119, 0.5);
	-moz-box-shadow: 0px 0px 6px 1px rgba(0, 183, 119, 0.5);
	box-shadow: 0px 0px 6px 1px rgba(0, 183, 119, 0.5);
}

@media only screen and (min-width: 1380px) {
	.ProjectGridItem {
		max-width: min(340px, 100vw);
		margin-bottom: var(--spacing-10x);
	}
}
