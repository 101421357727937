.Tag {
    align-items: center;
    background-color: var(--off-black-color);
    border-radius: 4px;
    color: var(--text-light-color);
    display: flex;
    font-weight: bold;
    justify-content: flex-start;
    padding: 0 var(--spacing-1x);
    white-space: nowrap; 
}

.Tag .Label {
    font-size: var(--font-size-xsmall);
}