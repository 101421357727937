.PageHeader {
    background-color: var(--black-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: calc(20px + var(--standard-banner-height));
    min-height: var(--standard-banner-height);
    left: 0;
    position: sticky;
    padding: var(--spacing-3x);
    top: 0;
    width: 100%;
    z-index: 10;
}

.PageHeader .ActionButton {
    max-width: 130px;
    padding: 0;
    text-align: right;
    color: #23d5ab;
    font-size: 17px;
}

.PageHeader.border-bottom {
    border-bottom: 1px dashed black;
}

.PageHeader .container {
    /* border-bottom: 2px solid var(--off-black-color); */
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    padding-left: 0;
	padding-right: 0;
    width: 100%;
}

.PageHeader .container nav a {
    cursor: pointer;
}

.PageHeader .container .titles {
    margin-left: 0;
    text-decoration: none;
}

.PageHeader .container .link {
    color: var(--grey1-color);
    font-weight: bold;
    text-decoration: none;
    margin-left: var(--spacing-4x);
    margin-right: var(--spacing-4x);
}

.PageHeader .container .titles:hover {
    color: var(--black-color);
}

.PageHeader .title {
    color: var(--white-color);
    font-size: 24px;
    font-weight: 700;
}

.PageHeader .subtitle {
    font-size: var(--font-size-small);
    font-weight: 400;
    margin-left: var(--spacing-2x);
}

.PageHeader .hamburger-react {
    color: var(--white-color);
}

.PageHeader .mobile-content-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: var(--spacing-1x);
    width: 100%;
}

.PageHeader .desktop-content-container {
    align-items: center;
    display: none;
    justify-content: space-between;
    padding-top: var(--spacing-1x);
    width: 100%;
}

.PageHeader .mobile-content-container .subtitle {
    margin-left: 0;
}

.PageHeader .mobile-content-container nav ul,
.PageHeader .desktop-content-container nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.PageHeader .mobile-content-container nav ul {
    margin-bottom: var(--spacing-4x);
}

.PageHeader .mobile-content-container .btn:not(:last-child) {
    margin-right: var(--spacing-4x);
}

.PageHeader nav a {
    font-size: var(--font-size-small);
    font-weight: 500;
    margin-left: var(--spacing-3x);
    text-decoration: none;
}

.PageHeader .dotvider {
    margin-left: var(--spacing-3x);
    font-size: 24px;
    max-height: 20px;
    line-height: 24px;
}

.PageHeader .left,
.PageHeader .middle,
.PageHeader .right {
    flex-basis: 33%;
    flex-grow: 1;
}

.PageHeader .left {
    align-items: center;
    justify-content: flex-start;
    flex-basis: 50%;
}

.PageHeader .middle {
    align-items: center;
    justify-content: center;
    flex-basis: 0%;
}

.PageHeader .right {
    align-items: center;
    justify-content: flex-end;
    flex-basis: 50%;
}

.PageHeader .logo {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: var(--spacing-2x);
}

@media only screen and (min-width: 600px) {
    .PageHeader .mobile-content-container {
        display: none;
    }

    .PageHeader .desktop-content-container {
        display: flex;
    }

    .PageHeader .left {
        flex-basis: 33%;
    }

    .PageHeader .middle {
        flex-basis: 33%;
    }

    .PageHeader .right {
        flex-basis: 33%;
    }
}
