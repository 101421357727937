.Product {
   border: 2px dashed var(--white-color);
   border-radius: 0px;
   height: 100%;
   cursor: pointer;
   padding: var(--spacing-6x);
   margin-bottom: var(--spacing-8x);
   width: 100%;
}

.Product .top {
   width: 100%;
}

.Product .name {
   color: var(--white-color);
   font-size: var(--font-size-medium);
   font-weight: bold;
}

.Product .prompt {
   color: var(--white-color);
   font-weight: bold;
}

.Product .prompt-detail {
   color: var(--grey3-color);
}

.Product .price {
   color: var(--white-color);
   font-size: var(--font-size-xlarge);
   font-weight: 700;
}

.Product .price-detail {
   color: var(--grey4-color);
   font-size: var(--font-size-small);
}

.Product .start {
   background-color: var(--black-color);
   border: 1px dashed var(--border-dark-color);
   border-radius: 6px;
   cursor: pointer;
   color: var(--white-color);
   font-weight: bold;
   outline: none;
   padding: var(--spacing-3x) var(--spacing-6x);
   margin-top: var(--spacing-6x);
   width: 100%;
}

.Product .includes-icon {
   color: var(--white-color);
}

.Product .includes {
   color: var(--white-color);
}

@media only screen and (min-width: 600px) {
   .Product {
      width: calc(50% - var(--spacing-4x));
      max-width: 370px;
      margin-bottom: 0;
   }
   
}
