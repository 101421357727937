.UpgradeFormPopup-content {
   background-color: var(--black-color);
   border: none;
   max-width: 100%;
   padding: 0;
   overflow: hidden;
   width: 100%;
}

.UpgradeFormPopup-content .close {
   background-color: var(--black-color);
   border: none;
   color: var(--white-color);
   position: absolute;
   cursor: pointer;
   right: var(--spacing-2x);
   top: var(--spacing-2x);
   padding: var(--spacing-2x);
   outline: none;
   z-index: 10;
}

.UpgradeForm {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   flex-direction: column;
   height: 100vh;
   overflow: auto;
   padding: var(--spacing-3x) var(--spacing-3x);
   width: 100%;
}

.UpgradeForm .products {
   align-items: center;
   display: flex;
   flex-direction: column;
   flex-grow: 1;
   justify-content: space-between;
   width: 100%;
}

.UpgradeForm .title-container {
   width: 100%;
}

.UpgradeForm .title {
   color: var(--white-color);
   font-size: var(--font-size-large);
   line-height: 50px;
   margin-top: 0;
   text-align: center;
   width: 100%;
}

.UpgradeForm .subtitle {
   margin-right: auto;
   text-align: center;
   font-size: 20px;
   width: 100%;
}

.UpgradeForm .billing-interval-container {
   margin-bottom: var(--spacing-4x);
}

.UpgradeForm .billing-interval-label-container.month {
   justify-content: flex-start;
}

.UpgradeForm .billing-interval-label {
   color: var(--white-color);
   font-weight: bold;
   font-size: var(--font-size-regular);
}

.UpgradeForm .billing-interval-discount {
   background-color: var(--light-grey2-color);
   border-radius: var(--spacing-2x);
   font-weight: bold;
   font-size: var(--font-size-xsmall);
   padding: var(--spacing-1x) var(--spacing-2x);
}

@media only screen and (min-width: 600px) {
   .UpgradeFormPopup-content {
       background-color: var(--black-color);
       max-width: 800px;
       max-height: min(740px, 90vh);
       height: 100%;
       border: 2px solid var(--white-color);
       width: 100%;
   }

   .UpgradeForm {
      height: 100%;
      overflow: auto;
      padding: var(--spacing-8x) var(--spacing-8x) var(--spacing-8x);
   }

   .UpgradeForm .products {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
   }

   .UpgradeFormPopup-content .close {
       background-color: var(--black-color);
   }

}
