.LastCTA {
   align-items: center;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   padding: var(--spacing-10x) 0;
   width: 100%;
}

.LastCTA .title {
   color: var(--white-color);
   font-size: var(--font-size-xlarge);
   text-align: center;
   line-height: 50px;
   max-width: 100%;
   margin-left: auto;
   margin-right: auto;
   margin-top: var(--spacing-8x);
}

.LastCTA .subtitle {
   font-size: 20px;
   text-align: center;
   line-height: 32px;
   max-width: 720px;
   margin-left: auto;
   margin-right: auto;
}

.LastCTA .action-label {
   color: var(--grey1-color);
   font-weight: normal;
}

.LastCTA .ActionButton {
   max-width: 250px;
   width: 100%;
}

@media only screen and (min-width: 600px) {

   .LastCTA {
      margin-top: var(--spacing-16x);
   }

   .LastCTA .title {
      font-size: 50px;
      line-height: 50px;
      max-width: 870px;
   }
   
   .LastCTA .subtitle {
      font-size: 20px;
      line-height: 32px;
   }

   .LastCTA .action-label {
      margin-bottom: var(--spacing-20x);
   }

}