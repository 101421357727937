/** @format */

.PersonPage .Header {
	padding: var(--spacing-6x) 0 var(--spacing-6x);
	position: relative;
	max-height: 100vh;
	max-width: none;
	top: 0;
	width: 100%;
}

.PersonPage .Header .subscription-container {
	top: 148px;
	position: absolute;
	left: 118px;
}

.PersonPage .Header .details {
	width: 100%;
}

.PersonPage .Header .name {
	color: var(--white-color);
	font-size: var(--font-size-medium);
	margin-top: var(--spacing-6x);
	text-align: left;
}

.PersonPage .Header .bio {
	color: var(--grey4-color);
	font-size: var(--font-size-small);
	line-height: 1.4;
	text-align: left;
}

.PersonPage .Header .upgrade,
.PersonPage .Header .edit {
	align-items: center;
	border: none;
	background-color: var(--light-grey4-color);
	border-radius: var(--spacing-1x);
	color: var(--text-dark-color);
	cursor: pointer;
	display: flex;
	font-size: var(--font-size-small);
	font-weight: bold;
	margin-top: var(--spacing-4x);
	outline: none;
	padding: var(--spacing-1x) var(--spacing-2x);
	text-decoration: none;
	transition: all 150ms linear;
}

.PersonPage .Header .upgrade {
	background-color: var(--green-color);
	margin-left: var(--spacing-2x);
}

.PersonPage .Header .upgrade:hover,
.PersonPage .Header .edit:hover {
	background-color: var(--light-grey2-color);
}

.PersonPage .Header .upgrade svg,
.PersonPage .Header .edit svg {
	margin-left: var(--spacing-2x);
	margin-top: 1px;
}

.PersonPage .Header .socials-container {
	margin-top: var(--spacing-4x);
}

.PersonPage .Header .twitter svg {
	color: #1DA1F2;
	margin-right: var(--spacing-2x);
}

.PersonPage .Header .youtube svg {
	color: #FF0000;
	margin-right: var(--spacing-2x);
}

.PersonPage .Header .github svg {
	color: rgb(247,248,250);
	margin-right: var(--spacing-2x);
}

.PersonPage .Header .instagram svg {
	color: white;
	margin-right: var(--spacing-2x);
}

.PersonPage .Header .linkedin svg {
	color: #0077B5;
	margin-right: var(--spacing-2x);
}

.PersonPage .Header .website {
	color: lightskyblue;
	cursor: pointer;
	font-size: var(--font-size-small);
	text-decoration: none;
}

@media only screen and (min-width: 1380px) {
	.PersonPage .Header {
		padding: var(--spacing-20x) var(--spacing-8x) var(--spacing-10x);
		max-width: min(480px, 100vw);
		position: sticky;
		top: 0;
	}

	.PersonPage .Header .Avatar {
		height: 120px;
		width: 120px;
	}
}
