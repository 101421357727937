.AuthFooter {
   align-items: flex-start;
   background-color: var(--black-color);
   display: flex;
   flex-direction: column;
   /* min-height: 80px; */
   justify-content: flex-start;
   padding: var(--spacing-3x);
   width: 100%;
}

.AuthFooter .Row {
   justify-content: space-between;
   width: 100%;
}

.AuthFooter .link,
.AuthFooter .copyright {
   color: var(--white-color);
   /* flex-grow: 1; */
   font-size: var(--font-size-small);
   font-weight: 600;
   outline: none;
   padding: var(--spacing-2x) var(--spacing-2x);
   text-align: center;
   text-decoration: none;
}

.AuthFooter .link.login {
   color: var(--green-color);
}

.AuthFooter .copyright {
   border-left: 0px;
   color: var(--grey2-color);
   font-weight: 400;
   text-align: left;
   padding-left: 0px;
   padding-right: 0px;
   margin-left: auto;
   margin-right: auto;
   margin-top: var(--spacing-3x);
   margin-bottom: var(--spacing-3x);
}

@media only screen and (min-width: 1380px) {
   /* .AuthFooter {
      display: none;
   } */
}