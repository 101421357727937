.SignUpFormPopup-content {
    background-color: var(--black-color);
    border: 0px;
    min-height: 100vh;
    max-width: 100%;
    padding: 0;
    overflow: hidden;
    width: 100%;
}

.SignUpFormPopup-content .close {
    background-color: var(--black-color);
    color: var(--white-color);
    position: absolute;
    cursor: pointer;
    right: var(--spacing-3x);
    top: var(--spacing-3x);
    padding: var(--spacing-2x);
    outline: none;
    z-index: 10;
}

.SignUpFormPopup-content button {
    border: 0;
    box-shadow: none;
    cursor: pointer;
    padding: var(--spacing-4x) var(--spacing-6x);
    outline: none;
    transition: all 150ms linear;
}

.SignUpForm {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: var(--spacing-3x) var(--spacing-3x);
    max-width: min(440px, 100vw);
}

.SignUpForm .inputs {
    min-height: 194px;
    position: relative;
    width: 100%;
}

.SignUpForm .inputs .prompt {
    color: var(--white-color);
    font-size: var(--font-size-regular);
    top: 65px;
    left: 20px;
    position: absolute;
}

.SignUpForm .form-error {
    color: var(--red-color);
    min-height: 22px;
    width: 100%;
}

.SignUpForm .inputs .Spinner,
.SignUpForm .inputs .valid,
.SignUpForm .inputs .invalid {
    font-size: var(--font-size-regular);
    top: 65px;
    right: 20px;
    position: absolute;
}

.SignUpForm .inputs .valid {
    color: var(--green-color);
}

.SignUpForm .inputs .invalid {
    color: var(--red-color);
}

.SignUpForm .container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
}

.SignUpForm .title {
    color: var(--text-light-color);
    font-size: var(--font-size-large);
    margin-top: 0;
    margin-right: auto;
}

.SignUpForm .subtitle {
    color: var(--white-color);
    font-size: var(--font-size-medium);
    text-align: left;
}

.SignUpForm input {
    margin-bottom: var(--spacing-4x);
    outline: none;
    padding: var(--spacing-3x) var(--spacing-4x);
    width: 100%;
}

.SignUpForm input.unique-link {
    padding-left: 91px;
}

.SignUpForm input::placeholder {
    color: var(--grey4-color);
}

.SignUpForm .input-note {
    color: var(--grey4-color);
    font-size: var(--font-size-small);
    font-weight: 400;
    text-align: left;
    width: 100%;
}

.SignUpForm .divider {
    background-color: var(--light-grey1-color);
    height: 1px;
    margin-bottom: var(--spacing-8x);
    margin-top: var(--spacing-14x);
    position: relative;
    width: 100%;
}

.SignUpForm .divider .Label {
    background-color: var(--black-color);
    position: absolute;
    padding: var(--spacing-4x);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.SignUpForm button {
    border: 0;
    box-shadow: none;
    cursor: pointer;
    margin-top: var(--spacing-4x);
    padding: var(--spacing-4x) var(--spacing-6x);
    outline: none;
    transition: all 150ms linear;
    width: 100%;
}

.SignUpForm .title-container {
    width: 100%;
}

.SignUpForm .back {
    background: var(--black-color);
    color: var(--white-color);
    cursor: pointer;
    font-size: var(--font-size-small);
    margin-right: 0;
    font-weight: bold;
    width: 0;
    transition: all 150ms linear;
    opacity: 0;
    width: 0;
}

.SignUpForm .back.show {
    background: var(--black-color);
    color: var(--white-color);
    font-size: var(--font-size-small);
    margin-right: var(--spacing-3x);
    margin-right: var(--spacing-3x);
    font-weight: bold;
    font-weight: bold;
    width: 40px;
    opacity: 1;
}

.SignUpForm .Label.join {
    color: var(--grey4-color);
}

.SignUpForm button.join,
.SignUpForm button.next {
    background: var(--black-color);
    border: 2px dashed var(--green-color);
    border-radius: var(--spacing-1x);
    color: var(--green-color);
    font-size: var(--font-size-small);
    font-weight: bold;
}

.SignUpForm button.join:hover,
.SignUpForm button.next:hover {
    background: var(--black-color);
}

.SignUpForm button.next {
    align-items: center;
    display: flex;
    margin-bottom: 97px;
    justify-content: center;
}

.SignUpForm button.next:disabled {
    background-color: var(--black-color);
    border-color: var(--border-dark-color);
    color: var(--border-dark-color);
}

.SignUpForm button.next svg {
    margin-left: var(--spacing-2x);
}

.SignUpForm button.google {
    align-items: center;
    background: var(--black-color);
    border: 2px dashed var(--border-dark-color);
    color: var(--white-color);
    display: flex;
    font-size: var(--font-size-small);
    font-weight: bold;
    justify-content: center;
    margin-bottom: var(--spacing-8x);
    margin-top: var(--spacing-3x);
}

.SignUpForm button.google:hover {
    border-color: var(--white-color);
}

.SignUpForm button.google .Label {
    margin-left: var(--spacing-2x);
}

@media only screen and (min-width: 600px) {
    .SignUpFormPopup-content {
        border: 1px solid var(--grey1-color);
        background-color: var(--black-color);
        max-width: 440px;
        min-height: 558px;
        border: 2px solid var(--white-color);
        width: 100%;
    }

    .SignUpForm {
        padding: var(--spacing-3x) var(--spacing-6x);
        max-height: 800px;
    }

    .SignUpFormPopup-content .close {
        background-color: var(--black-color);
    }

    .SignUpFormPopup-content .close:hover {
        border-color: var(--white-color);
    }
}
