.PaywallPage {
    height: 100vh;
}

.PaywallPage .MaxWidthContainer {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
}

.PaywallPage .MaxWidthContainer .content {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    margin-bottom: var(--spacing-40x);
}

.PaywallPage .title {
    color: var(--white-color);
    font-size: var(--font-size-large);
    font-weight: bold;
}

.PaywallPage .subtitle {
    color: var(--grey4-color);
    font-size: var(--font-size-regular);
    max-width: min(500px, 90%);
    margin-top: var(--spacing-2x);
    text-align: center;
}

.PaywallPage .checkout {
    border: 2px solid var(--green-color);
    color: var(--green-color);
    font-size: var(--font-size-regular);
    font-weight: bold;
    max-width: 300px;
    transition: all 150ms ease;
}

.PaywallPage .happy-face {
    max-width: 120px;
    margin-bottom: var(--spacing-6x);
    fill: var(--white-color);
}

.PaywallPage .checkout:hover {
    background-color: var(--green-color);
    color: var(--black-color);
}
