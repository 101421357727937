/** @format */

.Avatar {
	background-color: var(--white-color);
	border-radius: 50%;
	height: 70px;
	overflow: hidden;
	width: 70px;
}

.Avatar .placeholder {
	height: 100%;
	width: 100%;
}

.Avatar img {
	height: 100%; /*height of parent container*/
   width: 100%;
	object-fit: cover;
}

@media only screen and (min-width: 1380px) {
	.Avatar {
		height: 70px;
		width: 70px;
	}
}
