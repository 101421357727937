.JoinNowPlaceholder {
   border-radius: var(--spacing-4x);
   padding: var(--spacing-12x);
   width: 100%;
}

.JoinNowPlaceholder .empty {
   color: var(--color-black);
   font-size: 26px;
}

@media only screen and (min-width: 600px) {
   .JoinNowPlaceholder {
       border: 2px solid var(--light-grey4-color);
   }
}
