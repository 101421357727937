/** @format */

.Footer {
	align-items: flex-start;
	background-color: var(--black-color);
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: var(--spacing-12x) 0px var(--spacing-6x);
	width: 100vw;
}

.Footer .inner-container {
	height: 100%;
	width: 100%;
}

.Footer .subcontainer {
	align-items: flex-start;
	flex-direction: column;
	height: 100%;
	width: 100%;
}

.Footer .container {
	align-items: flex-start;
	display: flex;
	flex-direction: row;
	height: 100%;
	justify-content: flex-end;
	width: 100%;
}

.Footer .container .left {
	align-items: flex-start;
	color: var(--white-color);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	height: 100%;
}

.Footer .container .right {
	align-items: flex-start;
	color: var(--white-color);
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: flex-end;
	height: 100%;
}

.Footer .container .subheader {
	color: var(--grey2-color);
	margin-bottom: var(--spacing-12x);
	text-decoration: none;
}

.Footer .container a,
.Footer .container .email,
.Footer .container .links {
	color: var(--white-color);
	cursor: pointer;
	font-weight: 400;
	text-decoration: none;
}

.Footer .container .instagram {
	margin-right: var(--spacing-2x);
}

.Footer .container .right .contact {
	align-items: flex-start;
	margin-top: 0;
	min-width: 25%;
}

.Footer .container .right .nav,
.Footer .container .right .follow {
	align-items: flex-start;
	min-width: 20%;
}

.Footer .copyright {
	color: var(--grey3-color);
	font-size: var(--font-size-xsmall);
	font-weight: 400;
}

.Footer .twitter .link-primary {
	color: var(--black-color);
	font-size: var(--font-size-small);
}

@media only screen and (min-width: 600px) {
	.Footer .container {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.Footer .container .left {
		align-items: flex-start;
		flex-direction: row;
		justify-content: flex-start;
	}

	.Footer .container .right {
		align-items: flex-start;
		flex-direction: row;
		justify-content: flex-end;
	}
}

@media only screen and (min-width: 600px) {
	.Footer .subcontainer {
		flex-direction: column;
	}

	.Footer .container .right .contact {
		min-width: 30%;
	}

	.Footer .container .right .nav,
	.Footer .container .right .follow {
		min-width: 30%;
	}

	.Footer .container .right .nav,
	.Footer .container .right .follow {
		margin-top: 0;
	}
}

@media only screen and (min-width: 800px) {
	.Footer .subcontainer {
		flex-direction: row;
	}

	.Footer .container .right .contact {
		min-width: 25%;
	}

	.Footer .container .right .nav,
	.Footer .container .right .follow {
		min-width: 20%;
	}
}

@media only screen and (min-width: 1000px) {
	.Footer .container .right .contact {
		min-width: 20%;
	}

	.Footer .container .right .nav,
	.Footer .container .right .follow {
		min-width: 15%;
	}
}
