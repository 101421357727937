/** @format */

.EditProjectFormPopup-content {
	background-color: var(--black-color);
	max-height: 100vh;
    height: 100%;
	max-width: 100%;
	padding: 0;
	overflow: hidden;
	border: 0px;
	width: 100%;
}

.EditProjectFormPopup-content .close {
	background-color: var(--black-color);
    border-radius: 0px;
	color: var(--white-color);
	position: absolute;
	cursor: pointer;
	right: var(--spacing-2x);
	top: var(--spacing-2x);
	padding: var(--spacing-2x);
	z-index: 10;
}

.EditProjectForm {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	padding: var(--spacing-3x) var(--spacing-3x) var(--spacing-30x);
	max-width: min(600px, 100vw);
	position: relative;
	max-height: 100vh;
	height: 100%;
	overflow: auto;
}

.EditProjectForm .container {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	text-align: left;
	width: 100%;
}

.EditProjectForm .title {
	color: var(--text-light-color);
	font-size: var(--font-size-large);
	margin-bottom: 0;
	margin-top: 0;
}

.EditProjectForm .textbox-title.disabled {
	cursor: not-allowed;
	font-weight: bold;
	border: none;
	color: var(--grey2-color);
	user-select: none
}

.EditProjectForm .subtitle {
	color: var(--grey4-color);
	font-weight: bold;
	margin-bottom: var(--spacing-2x);
	margin-top: var(--spacing-6x);
}

.EditProjectForm .financials {
	width: 100%;
}

.EditProjectForm .financials .title {
	color: var(--black-color);
	font-size: var(--font-size-regular);
	font-weight: bold;
	margin-bottom: var(--spacing-2x);
	margin-top: var(--spacing-6x);
}

.EditProjectForm .financials .Row {
	margin-bottom: var(--spacing-2x);
	width: 100%;
}

.EditProjectForm .financials .public .subtitle,
.EditProjectForm .financials .mrr .subtitle {
	color: var(--text-medium-color);
	font-size: var(--font-size-small);
	margin-top: 0;
	margin-bottom: 0;
}

.EditProjectForm .financials .mrr input {
	margin-bottom: 0;
	margin-top: 0;
	max-width: max(25%, 120px);
	text-align: right;
}

.EditProjectForm input,
.EditProjectForm textarea {
	margin-bottom: var(--spacing-4x);
	outline: none;
	padding: var(--spacing-1x) var(--spacing-2x);
	width: 100%;
}

.EditProjectForm textarea {
	min-height: 120px;
	max-width: 100%;
	resize: none;
}

.EditProjectForm .divider {
	background-color: var(--light-grey1-color);
	height: 1px;
	margin-bottom: var(--spacing-8x);
	margin-top: var(--spacing-14x);
	position: relative;
	width: 100%;
}

.EditProjectForm .divider .Label {
	background-color: var(--black-color);
	position: absolute;
	padding: var(--spacing-4x);
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.EditProjectFormPopup-content button {
	border: 0;
	border-radius: var(--spacing-2x);
	box-shadow: none;
	cursor: pointer;
	padding: var(--spacing-4x) var(--spacing-6x);
	outline: none;
	transition: all 150ms linear;
}

.EditProjectFormPopup-content button.delete,
.EditProjectFormPopup-content button.submit {
	background: var(--black-color);
	color: var(--green-color);
	font-size: var(--font-size-small);
	font-weight: bold;
	max-width: 100%;
	width: 100%;
}

.EditProjectFormPopup-content button.delete {
	border: 2px dashed var(--red-color);
    color: var(--red-color);
}

.EditProjectFormPopup-content button.delete:hover,
.EditProjectFormPopup-content button.submit:hover {
	background: var(--black-color);
}

.EditProjectFormPopup-content button.submit:disabled {
    cursor: not-allowed;
    color: var(--border-dark-color);
}

.EditProjectFormPopup-content button.reset {
	align-items: center;
	background: var(--black-color);
	color: var(--white-color);
	display: flex;
	font-size: var(--font-size-small);
	font-weight: bold;
	justify-content: center;
	margin-bottom: 0;
	margin-right: var(--spacing-4x);
	max-width: 25%;
	width: 100%;
}

.EditProjectFormPopup-content button.close:hover {
	background: var(--black-color);
}

.EditProjectFormPopup-content button.reset:hover {
	background: var(--light-grey2-color);
}

.EditProjectFormPopup-content .join {
	margin-left: auto;
	margin-right: auto;
}

.EditProjectFormPopup-content .buttons {
	background-color: var(--black-color);
	border-top: 1px solid var(--border-dark-color);
	display: flex;
	position: fixed;
	justify-content: space-between;
	left: 0;
	bottom: 0;
	padding: var(--spacing-4x);
	width: 100%;
}

@media only screen and (min-width: 600px) {
	.EditProjectFormPopup-content {
		border: 2px solid var(--white-color);
		max-height: min(800px, 100vh);
		max-width: 600px;
		height: 100%;
		width: 100%;
	}

	.EditProjectForm {
		padding: var(--spacing-3x) var(--spacing-6x) var(--spacing-30x);
		max-height: min(800px, 100vh);
	}

	.EditProjectFormPopup-content .close {
		background-color: var(--black-color);
	}

	.EditProjectFormPopup-content .close:hover {
		background-color: var(--light-grey4-color);
	}
}
