/** @format */

.UploadAvatarForm {
	padding: var(--spacing-8x) 0 var(--spacing-2x);
}

.UploadAvatarForm input[type="file"] {
	display: none;
}

.UploadAvatarForm .fileUpload,
.UploadAvatarForm .fileRemove {
	align-items: center;
	border: none;
	background-color: var(--light-grey4-color);
	border-radius: var(--spacing-1x);
	color: var(--text-dark-color);
	cursor: pointer;
	display: flex;
	font-size: var(--font-size-small);
	font-weight: bold;
	outline: none;
	padding: var(--spacing-1x) var(--spacing-2x);
	text-decoration: none;
	transition: all 150ms linear;
}

.UploadAvatarForm .fileRemove {
	background-color: var(--grey2-color);
	color: var(--text-dark-color);
}

.UploadAvatarForm .fileUpload svg,
.UploadAvatarForm .fileRemove svg {
	margin-left: var(--spacing-2x);
	margin-top: 1px;
}
