.AddButton {
   align-items: center;
   background-color: var(--white-color);
   border-radius: 50%;
   border: 1px solid var(--border-color);
   box-shadow: var(--box-shadow);
   color: var(--black-color);
   cursor: pointer;
   display: flex;
   height: 60px;
   font-size: var(--font-size-large);
   justify-content: center;
   position: fixed;
   right: 20px;
   bottom: 20px;
   transition: all 100ms linear;
   width: 60px;
   z-index: 12;
}

.AddButton:hover {
   background-color: var(--off-black-color);
   color: var(--white-color);
   transform: scale(1.1,1.1);
}