/* Imports */

/* Blueprint */
@import "~normalize.css";

/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Vars */

/*
font-family: 'DM Sans', sans-serif;
font-family: 'DM Serif Display', serif;
font-family: 'Merriweather', serif;
font-family: 'Noto Sans', sans-serif;
*/

:root {
  --box-shadow: 0 2px 4px rgb(0 0 0 / 4%);
  --border-color: rgba(0,0,0,0.14);
  --border: 1px solid var(--border-color);
  --primary-foreground-color: #ffffff;
  --primary-background-color: var(--off-black-color);
  --text-dark-color: var(--black-color);
  --text-medium-color: var(--grey2-color);
  --text-light-color: var(--white-color);
  --border-dark-color: rgb(70,70,70);
  --border-dark-medium-color: #6d8385;
  --border-medium-color: #95a6a8;
  --border-light-color: #d4d4d4;
  --input-background-color: rgb(36,36,36);
  --input-border-color: var(--border-dark-color);
  --orange-color: #FB8B24;
  --pink-color: #FAA6FF;
  --red-color: #BB0A21;
  --green-color: #23d5ab;
  --dark-green-color: #226F54;
  --blue-color: #2589BD;
  --light-blue-color: #3a5cc2;
  --white-color: #ffffff;
  --off-white-color: rgb(250, 249, 247);
  --black-color: rgb(10,10,10);
  --off-black-color: #20252b;
  --grey1-color: #5f6b7c;
  --grey2-color: #738091;
  --grey3-color: #8f99a8;
  --grey4-color: #abb3bf;
  --grey5-color: #c5cbd3;
  --light-grey1-color: #d3d8de;
  --light-grey2-color: #dce0e5;
  --light-grey3-color: #e5e8eb;
  --light-grey4-color: #edeff2;
  --light-grey5-color: rgb(250, 250, 250);
  --font-size-xxlarge: 70px;
  --font-size-xlarge: 42px;
  --font-size-large: 32px;
  --font-size-medium: 24px;
  --font-size-regular: 16px;
  --font-size-small: 14px;
  --font-size-body: 16px;
  --font-size-xsmall: 12px;
  --font-size-tag: 12px;
  --spacing-1x: 4px;
  --spacing-2x: 8px;
  --spacing-3x: 12px;
  --spacing-4x: 16px;
  --spacing-5x: 20px;
  --spacing-6x: 24px;
  --spacing-7x: 28px;
  --spacing-8x: 32px;
  --spacing-9x: 36px;
  --spacing-10x: 40px;
  --spacing-12x: 48px;
  --spacing-13x: 52px;
  --spacing-14x: 56px;
  --spacing-16x: 64px;
  --spacing-18x: 72px;
  --spacing-20x: 80px;
  --spacing-22x: 88px;
  --spacing-24x: 96px;
  --spacing-26x: 104px;
  --spacing-28x: 112px;
  --spacing-30x: 120px;
  --spacing-32x: 128px;
  --spacing-40x: 160px;
  --max-page-width: 1000px;
  --mobile-max-page-width: calc(100vw - var(--spacing-10x));
  --standard-container-padding: var(--spacing-6x);
  --large-banner-height: var(--spacing-22x);
  --standard-banner-height: var(--spacing-13x);
  --standard-footer-height: 500px;
  --small-banner-height: var(--spacing-10x);
  --standard-border-radius: var(--spacing-3x);
  --min-button-height: 34px;
  --standard-font-family: "Noto Sans", sans-serif;;
  /* Toastify */
  --toastify-color-success: #00b777;
}

/* Clear styles */

/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: var(--standard-font-family);
}
/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  background-color: var(--input-background-color);
  border: 1px dashed var(--input-border-color);
  color: var(--text-light-color);
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  margin: 0;
}

h1 {
  font-size: var(--font-size-large);
  font-weight: bold;
}

h2 {
  color: var(--text-medium-color);
  font-size: var(--font-size-medium);
  font-weight: 400;
}

h3 {
  color: var(--text-medium-color);
  font-size: var(--font-size-regular);
  font-weight: 400;
}

h4 {
  font-size: var(--font-size-regular);
}

h5 {
  font-size: var(--font-size-regular);
}

p,
h6 {
  font-size: var(--font-size-small);
}

/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

/* Etc */

body {
  background-color: var(--black-color);
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  width: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* General */

a,
a:focus,
a:visited {
  color: inherit;
  transition: color 100ms ease-in;
}

a:hover {
  color: var(--grey3-color);
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.relative {
  position: relative;
}

.aspect-cover {
  aspect-ratio: 6/9;
  object-fit: cover;
}

.bold {
  font-weight: bold;
}

.colored-text {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Popup */

/* Animation */

.popup-content {
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

/* React Toggle */

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #32de84;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #32de84;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #32de84;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0;
}