.Pricing {
   align-items: center;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   padding: var(--spacing-10x) 0;
   margin-top: var(--spacing-8x);
   width: 100%;
}

.Pricing .title {
   color: var(--white-color);
   font-size: var(--font-size-xlarge);
   text-align: center;
   line-height: 50px;
   margin-bottom: var(--spacing-6x);
   max-width: 100%;
}

.Pricing .subtitle {
   font-size: 20px;
   text-align: center;
   line-height: 32px;
   max-width: 720px;
   margin-left: auto;
   margin-right: auto;
}

.Pricing .action-label {
   font-weight: normal;
}

.Pricing .PricingPlans {
   background-color: transparent;
}

@media only screen and (min-width: 600px) {

   .Pricing {
      margin-top: var(--spacing-16x);
   }
   
   .Pricing .title {
      font-size: 50px;
      line-height: 70px;
      max-width: 870px;
      margin-bottom: var(--spacing-6x);
   }
   
   .Pricing .subtitle {
      font-size: 20px;
      line-height: 32px;
   }

}