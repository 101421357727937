.PersonPage {
   min-height: 100vh;
   height: 100%;
   width: 100%;
}

.PersonPage .Loading {
   position: fixed;
   top: 0;
}

.PersonPage.empty {
   min-height: 50vh;
}

.PersonPage .grid {
   display: grid;
   height: 100%;
   grid-template-columns: 1fr;
   column-gap: auto;
}

@media only screen and (min-width: 1380px) {
   .PersonPage .grid {
      grid-template-columns: min(480px, 100vw) 1fr;
      column-gap: 124px;
   }
}