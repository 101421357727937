.ActionButton {
    background-color: var(--white-color);
    border: 2px solid transparent;
    border-radius: 6px;
    cursor: pointer;
    font-size: var(--font-size-small);
    font-weight: 600;
    outline: none;
    padding: var(--spacing-1x) var(--spacing-4x);
    text-decoration: none;
    transition: all 150ms ease;
    width: 100%;
}

/* Styles */
.ActionButton.style-light {
    background-color: var(--white-color);
}

.ActionButton.style-dark {
    background-color: var(--black-color);
    color: var(--white-color);
}

.ActionButton.style-color {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    color: var(--white-color);
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
}

.ActionButton.style-color:hover {
    background: transparent;
    border: 2px solid white;
    color: white;
    transition: all 150ms ease;
}

/* Sizes */
.ActionButton.size-big {
    padding: var(--spacing-2x) var(--spacing-6x);
}

.ActionButton.size-big p {
    font-size: var(--font-size-regular);
    font-weight: bold;
}

.ActionButton.size-medium {
    padding: var(--spacing-2x) var(--spacing-4x);
}
