/** @format */

.MaxWidthContainer {
	height: 100%;
	max-width: calc(var(--max-page-width) - var(--spacing-12x));
	margin-left: auto;
	margin-right: auto;
	padding-left: var(--spacing-3x);
	padding-right: var(--spacing-3x);
	width: 100%;
}


@media only screen and (min-width: 640px) {
	.MaxWidthContainer {
		padding-left: 0;
		padding-right: 0;
	}
}

@media only screen and (min-width: 1380px) {
	.MaxWidthContainer {
		max-width: var(--max-page-width);
		margin-left: auto;
		margin-right: auto;
	}
}
