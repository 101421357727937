/** @format */

.Hero {
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
	padding: var(--spacing-6x) 0;
	width: 100%;
}

.Hero .title {
	color: var(--white-color);
	font-size: 40px;
	text-align: center;
	line-height: 50px;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.Hero .subtitle {
	color: var(--grey4-color);
	font-size: 20px;
	text-align: left;
	line-height: 32px;
	max-width: 720px;
	margin-left: auto;
	margin-right: auto;
}

.Hero .action-label {
	color: var(--white-color);
	font-size: var(--font-size-small);
	font-weight: normal;
	text-align: center;
}

.Hero .siblings {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.Hero .hero-picture {
	background-color: var(--off-black-color);
	border: 2px dashed var(--border-dark-color);
	border-radius: 20px;
	cursor: pointer;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	max-width: 90%;
}

.Hero .marketing {
	align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: var(--spacing-6x);
	margin-top: var(--spacing-2x);
}

.Hero .ActionButton {
	max-width: min(100%, 280px);
	width: 100%;
 }


.Hero .hero-picture img {
	max-height: 900px;
}

.Hero .ActionButton.mobile-cta {
	max-width: min(100%, 280px);
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}

@media only screen and (min-width: 400px) {
	.Hero {
		padding: 0;
	}
}

@media only screen and (min-width: 600px) {

	.Hero {
		padding: var(--spacing-22x) 0;
	}

	.Hero .ActionButton.mobile-cta {
		display: none;
	}

	.Hero .siblings {
		align-items: flex-start;
		flex-direction: row;
		justify-content: flex-start;
	}

	.Hero .title {
		font-size: 60px;
		line-height: 68px;
		max-width: 870px;
	}

	.Hero .subtitle {
		font-size: 20px;
		line-height: 32px;
	}

	.Hero .action-label {
		margin-bottom: var(--spacing-6x);
	}

	.Hero .hero-picture {
		margin-left: 60px;
		margin-right: 60px;
		max-width: unset;
	}
}
