.OneTimePayment {
   background-color: var(--black-color);
   width: 100%;
}

.OneTimePayment .products {
   background-color: var(--black-color);
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
}

.OneTimePayment .products .Product {
   background-color: var(--black-color);
   max-width: 100%;
   margin-bottom: var(--spacing-10x);
}

@media only screen and (min-width: 600px) {
   
   .OneTimePayment .products {
      flex-direction: row;
      justify-content: center;
   }

   .OneTimePayment .products .Product {
      align-self: stretch;
      height: 100%;
      min-height: 462px;
      flex: 1 1;
      width: calc(50% - var(--spacing-2x));
      max-width: 380px;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
   }
}

@media only screen and (min-width: 800px) {
   
   .OneTimePayment .products {
      flex-direction: row;
      justify-content: center;
   }
   
   .OneTimePayment .products .Product {
      align-self: stretch;
      height: 100%;
      min-height: 462px;
      flex: 1 1;
      width: calc(50% - var(--spacing-2x));
      max-width: 380px;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
   }

   .OneTimePayment .products .Product:first-child {
      margin-left: 0px;
   }

   .OneTimePayment .products .Product:last-child {
      margin-right: 0px;
   }
}

