.Selector {
   border: 1px dashed var(--border-dark-color);
   overflow: hidden;
   flex-wrap: wrap;
   width: 100%;
}

.Selector .option {
   align-items: center;
   background-color: var(--black-color);
   border-bottom: 1px dashed var(--border-dark-color);
   color: var(--white-color);
   cursor: cell;
   display: flex;
   flex-grow: 1;
   justify-content: center;
   max-width: 50%;
   min-width: 49%;
   padding: var(--spacing-4x) var(--spacing-2x);
}

.Selector .option:active,
.Selector .option:focus {
   background-color: var(--black-color);
}

.Selector .option.selected,
.Selector .option.selected:active,
.Selector .option.selected:focus {
   background-color: var(--off-black-color);
}

.Selector .option:hover {
   background-color: var(--off-black-color);
}

.Selector .option + .option {
   border-left: 1px dashed var(--border-dark-color);
}

.Selector .option:nth-child(2n + 1) {
   border-left: 0;
}

.Selector .option:last-child,
.Selector .option:nth-last-child(2) {
   border-bottom: 0;
}

.Selector .state {
   font-size: var(--font-size-small);
   font-weight: bold;
}