.OneTimeProduct {
    border: 2px dashed var(--white-color);
    border-radius: 0px;
    height: 100%;
    cursor: pointer;
    padding: var(--spacing-6x);
    margin-bottom: var(--spacing-8x);
    width: 100%;
 }
 
 .OneTimeProduct .top {
    width: 100%;
 }
 
 .OneTimeProduct .name {
    color: var(--white-color);
    font-size: var(--font-size-medium);
    font-weight: bold;
 }
 
 .OneTimeProduct .prompt {
   color: var(--white-color);
   font-size: var(--font-size-small);
   font-weight: bold;
   margin-top: var(--spacing-6x);
}

 .OneTimeProduct .price-note {
    color: var(--grey4-color);
    font-size: var(--font-size-small);
 }
 
 .OneTimeProduct .prompt-detail {
    color: var(--grey3-color);
    margin-top: var(--spacing-1x);
 }

 .OneTimeProduct .usual-price {
   color: var(--white-color);
   font-size: 50px;
   font-weight: bold;
   margin-right: var(--spacing-4x);
   text-decoration: line-through;
}
 
 .OneTimeProduct .price {
    color: var(--white-color);
    font-size: 50px;
    font-weight: 700;
 }
 
 .OneTimeProduct .price-detail {
    color: var(--grey4-color);
    font-size: var(--font-size-small);
 }
 
 .OneTimeProduct .start {
    background-color: var(--black-color);
    border: 1px dashed var(--green-color);
    border-radius: 6px;
    cursor: pointer;
    color: var(--green-color);
    font-weight: bold;
    outline: none;
    padding: var(--spacing-3x) var(--spacing-6x);
    margin-top: var(--spacing-6x);
    transition: all 150ms linear;
    width: 100%;
 }

 .OneTimeProduct:hover .start {
   border: 1px dashed var(--green-color);
   background-color: var(--green-color);
    color: var(--black-color);
 }
 
 .OneTimeProduct .includes-icon {
    color: var(--white-color);
 }
 
 .OneTimeProduct .includes {
    color: var(--white-color);
 }
 
 @media only screen and (min-width: 600px) {
    .OneTimeProduct {
       width: calc(70% - var(--spacing-4x));
       max-width: 420px;
       margin-bottom: 0;
    }
    
 }
 