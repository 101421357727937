.FAQs {
   max-width: 870px;
   margin-left: auto;
   margin-right: auto;
   margin-top: var(--spacing-16x);
   width: 100%;
}

.FAQs .HighlightedLabel {
   margin-left: auto;
   margin-right: auto;
}

.FAQs .title {
   color: var(--white-color);
   font-size: var(--font-size-xlarge);
   line-height: 50px;
   font-weight: bold;
   margin-left: auto;
   margin-right: auto;
   text-align: center;
   width: 100%;
}

.FAQs .collapse-wrapper {
   border-top: 1px dashed var(--border-dark-color);
   cursor: pointer;
   width: 100%;
}

.FAQs .collapse-wrapper:last-child {
   border-bottom: 1px dashed var(--border-dark-color);
}

.FAQs .question {
   border-radius: var(--spacing-3x);
   transition: all 150ms linear;
   width: 100%;
}

.FAQs .question.open {
   margin-bottom: var(--spacing-6x);
   width: 100%;
}

.FAQs .question .inner-content {
   background-color: var(--black-color);
   border-bottom-left-radius: var(--spacing-3x);
   border-bottom-right-radius: var(--spacing-3x);
   padding-bottom: var(--spacing-6x);
}

.FAQs .question .inner-content p {
   color: var(--grey4-color);
   font-size: 18px;
}

.FAQs .question .Trigger {
   background-color: var(--black-color);
   cursor: pointer;
   justify-content: space-between;
   min-height: 60px;
   transition: all 150ms linear;
   padding: var(--spacing-6x) 0;
   width: 100%;
}

.FAQs .question .Trigger p {
   color: var(--white-color);
   font-size: var(--font-size-small);
   font-weight: bold;
}

.FAQs .question.open .Trigger {
   border-bottom-left-radius: 0px;
   border-bottom-right-radius: 0px;
   padding-bottom: var(--spacing-3x);
}

.FAQs .collapse-wrapper:hover .question.open .icon-wrapper {
   transform: rotate(45deg);
}

.FAQs .question .Trigger .icon-wrapper {
   background-color: var(--black-color);
   border: 1px dashed var(--border-dark-color);
   border-radius: 50%;
   color: white;
   padding: var(--spacing-1x);
   transition: all 150ms linear;
}

.FAQs .collapse-wrapper:hover .question .icon-wrapper {
   background-color: var(--black-color);
}

@media only screen and (min-width: 600px) {

   .FAQs {
      margin-top: var(--spacing-16x);
   }

   .FAQs .title {
      font-size: 50px;
      line-height: 50px;
      max-width: 870px;
   }
   
   .FAQs .subtitle {
      font-size: 20px;
      line-height: 32px;
   }

   .FAQs .action-label {
      margin-bottom: var(--spacing-20x);
   }

}